@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: 'Inter';
  /* src: url('https://local.geohub.ai:3001/fonts/webui/Inter/Inter-Medium.ttf'); */
  src: url('./../public/fonts/webui/Inter/Inter-Medium.ttf');
  /* src: local('./fonts/webui/Inter/Inter-Medium.ttf'); */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', sans-serif !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1c1c1c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(68, 68, 68, 1);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(68, 68, 68, 1);
}

.divLogNav {
  align-items: center;
  text-align: center;
  display: flex;
}

.divCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.div-list {
  max-height: calc(100vh - 58px - 24px - 24px);
  overflow: auto;
}

.img-svg-icon {
  max-width: 20px;
  height: 20px;
}

.img-svg-icon-small {
  max-width: 18px;
  height: 18px;
}

.img-svg {
  max-width: 40px;
  height: 40px;
}

.divBtnPagination {
  border: 1px solid #ffffff;
  min-height: 24px;
  min-width: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.divBtnPaginationAction {
  border: none;
  min-height: 30px;
  min-width: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(56, 56, 56, 0.7) !important;
  border-radius: 2px;
}

.pre {
  background-color: black;
}

.next {
  background-color: #ffffffb2;
}

.active {
  justify-content: left;
  /* border-left-color: #60CDFF;
  border-left-width: 3px; */
  display: block;
  /* border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent; */
  background: #272D3C;
  border-radius: 3px;
  border: none !important;
  width: 100%;

}

.btn-cus {
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
}

.btn-cus-primary {
  background: linear-gradient(0deg, #60cdff, #60cdff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  border: 1px solid;

  border-image-source: linear-gradient(0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2));
  color: black !important;

  padding: 0px 10px 0px 10px !important;
}

.card-item-download {
  width: 100%;
  max-width: 100%;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.08) !important;
  border-radius: 3px !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.card-item-order-img {
  width: 100%;
  max-width: 100%;
  height: 120px;
  background: rgba(44, 44, 44, 1) !important;

  border-radius: 3px !important;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
}

.card-item {
  width: 100%;
  max-width: 100%;
  height: 100px;
  background: rgba(44, 44, 44, 1) !important;

  border-radius: 4px !important;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
  margin-bottom: 10px;
}

.card-item-storage:hover {
  background: #3e4353 !important;

  .div-action-card {
    display: flex;
    align-items: center;
  }

  .div-action-card-scheduler {
    display: flex;
    align-items: center;
  }

  .footer-card-action {
    display: flex;
    justify-content: end;

    align-items: center;
    flex-direction: row !important;
  }

  .divHeader {
    width: calc(100% - 100px);
  }
}

.card-item-resource {
  width: 100%;
  max-width: 100%;
  background: rgba(44, 44, 44, 1) !important;

  border-radius: 3px !important;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
}

.divHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.divTitle {
  width: 45%;
}

.divSpot {
  width: 5%;
  text-align: center;
}

.divDes {
  width: 45%;
  padding: 0 10px 0 10px;
}

.divDungLuong {
  width: 5%;
  text-align: center;
}

.tag-accept {
  /* background: linear-gradient(0deg, #283E33, #283E33), linear-gradient(0deg, #1F6342, #1F6342);
  border: 1px solid rgba(31, 99, 66, 1); */
  border-radius: 4px;
  color: rgba(0, 230, 118, 1);
}

.tag-reject {
  /* background: linear-gradient(0deg, #3D2C28, #3D2C28),
    linear-gradient(0deg, #612C1F, #612C1F);
  border: 1px solid rgba(97, 44, 31, 1); */
  color: rgba(221, 44, 0, 1);
}

.accoding-item {
  background: #383838;
  border-radius: 4px;
  padding-bottom: 16px;
  border: 1px solid rgba(86, 86, 86, 1);
  margin-bottom: 12px;
}

.buttonFliterSearh {
  background: #2c2c2c !important;
  height: 32px !important;
  width: 32px !important;
  min-width: 32px !important;
}

.buttonFliterSearh:focus {
  background: #60cdff !important;
}

.buttonFliterSearh:focus-within {
  background: #60cdff !important;
}

.buttonSearhMap {
  background: #2c2c2c !important;
}

.buttonSearhMap:focus {
  background: #60cdff !important;
}

.buttonSearhMap:focus-within {
  background: #60cdff !important;
}

.buttonSearhMap:active {
  background: #60cdff !important;
}

.down-line {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

.title {
  margin-bottom: 100px;
}

.div-step-item {
  text-align: center;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.div-center {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.card-default {
  background: linear-gradient(0deg, #393939, #393939),
    linear-gradient(0deg, #565656, #565656);
  padding: 25px !important;
  width: 100%;
  border: 1px solid rgb(86, 86, 86);
  box-shadow: none;
}

.card-default-active {
  background: rgba(86, 173, 213, 0.4) !important;
  border: 1px solid rgba(96, 205, 255, 1);
  padding: 25px !important;
  width: 100%;
  box-shadow: none;

  .bg-icon-card {
    background: rgba(96, 205, 255, 1);
    border-radius: 50%;
  }
}

.card-default:hover {
  background: rgba(86, 173, 213, 0.4);
  border: 1px solid rgba(96, 205, 255, 1);
  color: white;

  .bg-icon-card {
    background: rgba(96, 205, 255, 1);
    border-radius: 50%;
  }
}

.bg-icon-card {
  background: rgba(149, 149, 149, 1);
  border-radius: 50%;
}

.bg-icon-card:hover {
  background: rgba(96, 205, 255, 1);
}

.btn-back {
  background: none !important;
  border: 1px solid rgba(96, 205, 255, 1) !important;
  color: rgba(96, 205, 255, 1) !important;
}

.img-svg-icon-menu {
  max-width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
}

.img-svg-icon-paging {
  max-width: 12px;
  height: 12px;
}

.radio-cus {
  position: absolute;
  left: 0px;
  top: 0px;
  width: calc(16px + 2 * var(--spacingHorizontalS));
  height: 100%;
  box-sizing: border-box;
  margin: 0px;
  opacity: 0;
}

.divStep {
  background: linear-gradient(0deg, rgb(28, 28, 28), rgb(28, 28, 28)),
    linear-gradient(0deg, rgb(86, 86, 86), rgb(86, 86, 86));
  border: 1px solid rgb(86, 86, 86);
  border-radius: 20px;
  padding: 8px;
  width: 50px;
  text-align: center;
}

.divFooterStep {
  position: absolute;
  /* bottom: 45px; */
  display: flex;
  justify-content: space-between;
  /* width: 97%; */
}

.img-svg-icon-add {
  max-width: 28px;
  height: 28px;
}

.img-svg-icon-paging {
  max-width: 12px;
  height: 12px;
}

.divBtnPagination {
  border: 1px solid #ffffff;
  min-height: 24px;
  min-width: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.divBtnPaginationAction {
  border: none;
  min-height: 30px;
  min-width: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(49, 59, 91, 0.7) !important;
  border-radius: 2px;
}

.children {
  overflow: hidden;
  background: #232735 !important;
  display: flex;
  height: calc(100vh - 58px - 57px);
  /* overflow: auto; */
}

.div-layout-content-open {
  width: calc(100vw - 268px) !important;
}

.div-layout-content-close {
  width: calc(100vw - 44px) !important;
}

.sidebar {
  position: relative;
  overflow: unset !important;
  min-height: 300px !important;
}

.sidebar-open {
  width: 268px !important;
  max-width: 268px !important;
}

.sidebar-close {
  width: 44px !important;
  min-width: 44px !important;
  max-width: 44px !important;
}

.bg-s-background2 {
  --tw-bg-opacity: 1;
  background-color: rgb(35 39 53 / var(--tw-bg-opacity));
}

.border-s-line1 {
  --tw-border-opacity: 1;
  border-color: rgb(53 59 79 / var(--tw-border-opacity));
}

.border-t {
  border-top-width: 1px;
}

.div-form-storage {
  background: #232735;
  height: calc(-191px + 100vh);
  position: relative;
  padding: 45px 30px 30px;
  overflow: auto;
  /* border: 1px solid rgb(86, 86, 86); */
}

.div-form-storage-small {
  background: #232735;
  height: calc(-191px + 100vh);
  position: relative;
  padding: 45px 25% 45px 25%;
  overflow: auto;
  /* border: 1px solid rgb(86, 86, 86); */
}

@media only screen and (max-width: 1218px) {
  .divTitle {
    width: 100%;
  }

  .divSpot {
    display: none;
  }

  .divDes {
    display: none;
  }

  .divDungLuong {
    display: none;
  }
}

@media only screen and (max-width: 980px) {
  .divHeader {
    width: 80%;
  }

  .card-item-download {
    max-width: 97%;
  }
}

@media only screen and (max-width: 956px) {
  .card-item-download {
    max-width: 95%;
  }
}

@media only screen and (max-width: 868px) {
  .card-item-download {
    max-width: 85%;
  }
}

@media only screen and (max-width: 800px) {
  .divTitle {
    display: none !important;
  }
}

.btn-extra-action {
  margin-right: 8px !important;
  background: none !important;
  border: 1px solid rgb(96, 205, 255) !important;
  margin-top: 12px !important;
  width: 100% !important;
  cursor: pointer !important;
  font-weight: 100 !important;
  color: rgb(96, 205, 255) !important;
}

.btn-action {
  margin-right: 8px !important;
  background: rgb(96, 205, 255) !important;
  border: medium !important;
  margin-top: 12px !important;
  width: 100% !important;
  color: black !important;
  cursor: pointer !important;
  font-weight: 100 !important;
}

.div-form-default {
  background: rgb(44, 44, 44);
  height: calc((100vh - 80px) - 102px);
  position: relative;
  padding: 45px 30px 30px;
}

.menu-popover {
  top: 12px !important;
}

.btn-action-card {
  background: rgba(56, 56, 56, 1) !important;
  border: none !important;
  border-radius: 2px;
}

.btn-clear {
  background: rgb(56, 56, 56, 1) !important;
  border-color: rgb(56, 56, 56, 1) !important;
  margin-bottom: 8px !important;
}

.btn-apply {
  border: 1px solid rgba(255, 255, 255, 0.03) !important;
  width: 100% !important;
  background: rgb(96, 205, 255) !important;
  color: black !important;
}

.schemaPre {
  overflow-y: scroll;
  max-height: calc(100vh - 57px - 58px - 250px);
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom: 1px solid #9b9da3 !important;
  border-radius: 5px;
  padding: 5px;
  background-color: #2e3240;
}

.title-div-form {
  font-size: 18px;
  font-weight: 600;
  line-height: 2;
}

.div-dropdown {
  width: 100%;
  background: #2e3240 !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #9b9da3 !important;
}

.card-item-storage {
  width: 100%;
  max-width: 100%;
  height: 78px;
  background: #2e323e;
  border-radius: 3px !important;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
  margin-bottom: 8px;
  padding: 16px 24px 16px 24px !important;
  min-width: 400px !important;
}

.footer-card-action {
  /* display: flex;
  justify-content: space-between; */

  align-content: flex-end;
  align-items: end;
  flex-direction: column !important;
  width: 250px !important;
  max-width: 250px !important;
}

.div-card-header {
  width: calc(100% - 70px - 250px) !important;
  height: 100% !important;
}

.div-card-header-with-checkbox {
  width: calc(100% - 70px - 250px - 40px) !important;
  height: 100% !important;
}

.divHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.div-action-card {
  display: none;
}

.bg-default {
  background: #2e3240 !important;
}

.img-card-item-catalog {
  min-height: 32px !important;
  min-width: 32px !important;

  height: 32px !important;
  width: 32px !important;
}

/* .img-svg-icon {
  max-width: 16px;
  height: 16px;
} */

.img-svg-icon-add {
  max-width: 28px;
  height: 28px;
}

.img-svg {
  max-width: 40px;
  height: 40px;
}

.btn-active {
  background: #4CC2FF !important;
}

.btn-add-new-dropdown {
  width: 150px;
  height: 30px !important;
  min-height: 30px !important;
  position: absolute;
  top: 6px;
  right: 0px;
  z-index: 9999;
}

.text-color-des {
  color: #9B9DA3 !important;
}

.autocomplete-wrapper {
  /* width: 350px; */
  position: relative;
  display: inline-block;

  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #9b9da3 !important;
  border-radius: 4px;
}

.autocomplete-wrapper>div {
  width: 100%;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #9b9da3 !important;
  border-radius: 4px;
}

.autocomplete-wrapper input {
  border: 1px solid #cecece;
  padding: 2px 15px;
  font-size: 14px;
  width: 100%;
  background: #2e3240 !important;
  border-radius: 4px;
  height: 32px;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #9b9da3 !important;
}

.autocomplete-wrapper input:focus {
  border-color: #e5e7eb;
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;

  position: absolute;
  z-index: 999;
  background: #1c1c1c;

  padding: 8px;
  border-radius: 4px;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 13px;
  padding: 6px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #3e3d3d;
  color: #fafbfc;
}

.autocomplete-wrapper .item:hover {
  background-color: #3e3d3d;
  color: #fafbfc;
}

.fui-DrawerHeaderTitle__heading {
  width: calc(100% - 32px);
}

.fui-DrawerHeaderTitle__action {
  margin-right: 0px !important;
}

.btn-action-drawer {
  display: flex !important;
  padding: 4px 12px 4px 8px;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  border-radius: 4px !important;
  background: #272d3c !important;
  border: none !important;
  font-weight: 100;
  font-size: 14px;
}

.drawer-cus {
  width: 450px !important;
  right: 0px;
  min-width: 300px;
  overflow-x: auto !important;
}

.fui-Dropdown__listbox {
  max-height: 300px !important;
}

/* css cutomise menu */
.active {
  justify-content: left;
  /* border-left-color: #60CDFF;
  border-left-width: 3px; */
  display: block;
  /* border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent; */
  background: #272D3C;
  border-radius: 3px;
  border: none !important;
  width: 100%;

}

.active-menu {
  height: inherit;
  width: 3px;
  background: url(./assets/images/Selector.png) center left no-repeat;
}

.div-menu-btn {
  display: flex;
  height: 36px;
  align-items: center;
}

.div-menu-btn.active {
  background: #272D3C;
  border-radius: 3px;
}

.div-menu-btn::before {
  content: '';
  position: relative;
  width: 3px;
  right: 0;
  /* top: 8px; */
  background: transparent;
  height: 50%;
  border-radius: 12px
}

.div-menu-list {
  position: absolute;
  left: 44px;
  z-index: 9999;
  background: #1A2032;
  border-radius: 7px;

  .fui-MenuItem {
    background: none;
  }

}

.div-menu-btn.active::before {
  background: #4CC2FF;
}

.bg-s-background1 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(26 32 50 / var(--tw-bg-opacity)) !important;
}

.div-menu-list-select-object {
  height: 500px;
  overflow: auto;
  width: 100%;
}

.active-menu {
  height: inherit;
  width: 3px;
  background: url(./assets/images/Selector.png) center left no-repeat;
}

/* end css customise menu */



/* loading */
/* Absolute Center Spinner */
.loading {
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #090b13;

  opacity: 0.8;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: none !important;
  font-size: 10px;
  width: 1em;
  height: 1em;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  /* margin-top: -0.5em; */
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* end loading */

@media screen and (max-width: 1366px) {
  .drawer-cus {
    width: 278px;
  }
}

@media screen and (max-width: 1218px) {
  .divTitle {
    width: 100%;
  }

  .divSpot {
    display: none;
  }

  .divDes {
    display: none;
  }

  .divDungLuong {
    display: none;
  }

  .div-form-storage-small {
    padding: 29px 10% 20px 10% !important;
  }
}

@media screen and (max-width: 980px) {
  .divHeader {
    width: 80%;
  }

  .card-item-download {
    max-width: 97%;
  }
}

@media screen and (max-width: 956px) {
  .card-item-download {
    max-width: 95%;
  }
}

@media screen and (max-width: 868px) {
  .card-item-download {
    max-width: 85%;
  }

  .div-form-storage-small {
    padding: 25px 10% 25px 10% !important;
  }
}

@media screen and (max-width: 800px) {
  .divTitle {
    display: none !important;
  }

  .sidebar-open {
    width: 158px !important;
  }

  .div-layout-content-open {
    width: calc(100vw - 158px) !important;
  }

  .card-item-storage {
    padding: 10px 16px 10px 16px !important;
  }

  .div-drawer-body {
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-top: 0px !important;
  }
}

@media screen and (max-height: 495px) {
  .divSubMenu {
    position: relative !important;
  }
}

.fui-Button:hover:active {
  border: none !important
}

.fui-MenuItem__content {
  text-wrap: wrap;
  text-overflow: ellipsis;
  overflow: auto;
  word-wrap: break-word;
}